import React, { Component } from 'react';
import Drawer from 'antd/lib/drawer';
import Typography from 'antd/lib/typography';
import { connect } from "react-redux";
import { tranforobj } from './config';
import TranforCoins from './swapCoins';
import TransforSummary from './transferSummary';
import TransforSuccessMsg from './transferSuccess';
import {setTransforObj, setStepcode} from '../../reducers/transferReducer'
import Title from 'antd/lib/typography/Title';

const {Text} = Typography
class Transfor extends Component {
    state = {

    }
    clearValues() {
        if (this.child)
            this.child.clearSwapCoinValues();
    }
    closeBuyDrawer = () => {
        this.props.dispatch(setTransforObj(null))
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.props.dispatch(setStepcode("tranforcoin"))
    }
    closeDrawer=()=>{
        this.closeBuyDrawer();
       this.props.dispatch(setStepcode("tranforcoin"))
    }
    renderContent = () => {
        const stepcodes = {
            tranforcoin: <TranforCoins onClose={()=>this.closeBuyDrawer()}/>,
            tranforsummary: <TransforSummary onClose={()=>this.closeBuyDrawer()}/>,
            tranforsuccess: <TransforSuccessMsg onClose={()=>this.closeBuyDrawer()}/>,
        }
        return stepcodes[tranforobj[this.props.TransforStore.stepcode]]
    }
    renderTitle = () => {
        const stepcodes = {
            tranforcoin: <span/>,
            tranforsummary: <span onClick={() => this.props.dispatch(setStepcode("tranforcoin"))} className="icon md lftarw-white c-pointer" />,
            tranforsuccess: <span />,
        }
        return stepcodes[tranforobj[this.props.TransforStore.stepcode]]
    }
    renderIcon = () => {
        const stepcodes = {
            tranforcoin: <span onClick={this.closeBuyDrawer} className="icon md close-white c-pointer" />,
            tranforsummary: <span onClick={this.closeBuyDrawer} className="icon md close-white c-pointer" />,
            tranforsuccess: <span onClick={this.closeDrawer} className="icon md close-white c-pointer" />,

        }
        return stepcodes[tranforobj[this.props.TransforStore.stepcode]]

    }
    render() {
        return (<Drawer
            title={[
            <div className="side-drawer-header">
                {this.renderTitle()}
                <div className="text-center fs-16"> 
                
                     <Title className="text-white-30 fw-600 text-upper mb-4 fs-16" >{this.props.TransforStore.stepTitles[tranforobj[this.props.TransforStore.stepcode]]}</Title>
                      <Text className="text-white-50 mb-0 fw-300 fs-14 swap-subtitlte" >{this.props.TransforStore.stepSubTitles[tranforobj[this.props.TransforStore.stepcode]]}</Text>
                    </div>
                {this.renderIcon()}
                </div>]}
            placement="right"
            closable={true}
            visible={this.props.showDrawer}
            onClose={this.props.onClose}
            closeIcon={null}
            className="side-drawer"
            destroyOnClose={true}
            maskClosable={false}
        >
            {this.renderContent()}
        </Drawer>);
    }
}


const connectStateToProps = ({ userConfig,TransforStore }) => {
    return {
        userConfig: userConfig.userProfileInfo,
        TransforStore
    };
};
export default connect(connectStateToProps)(Transfor);