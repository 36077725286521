import { Component } from 'react';
import Typography from 'antd/lib/typography';
import Menu from 'antd/lib/menu';
import Layout from 'antd/lib/layout';
import Dropdown from 'antd/lib/dropdown';
import Collapse from 'antd/lib/collapse';
import logoWhite from "../assets/images/logo-white.png";
import { userManager } from '../auth';
import { connect } from 'react-redux';
import DefaultUser from "../assets/images/defaultuser.jpg";
import { Link } from 'react-router-dom';
import Transfor from '../components/transfer.component'
const { Text } = Typography;
const { Panel } = Collapse;

const { Sider } = Layout;
class AppHeader extends Component {
  state={
    selectedkeys:'1',
    isShowSider: false,
    collapsed:true,
    transferDrawer:false
  }
  userProfileMenu = (
    <Menu>
          <div className="profile-dropdown">
              <img
                src={
                  DefaultUser
                }
                className="user-profile"
                alt={"image"}
              />
             
            <p className="mb-15 profile-value" style={{ flexGrow: 12 }}>
            {this.props.userConfig.isBusiness ? this.props.userConfig.businessName:this.props.userConfig.firstName }
            </p>
            <ul className="pl-0 drpdwn-list">           
              <li>
                <Link>
                  <Text
                    content="AuditLogs"
                    component={Text}
                    className="text-white-30"
                    onClick={() => {
                      userManager.signoutRedirect();
                    }}
                  >
                   Logout
                    </Text>
                </Link>
              </li>         
            </ul>
          </div>
        </Menu>
  );
  onClickmenu = (e) =>{
    this.setState({selectedkeys:e})
  } 
  routeToHome = () => {
    this.props.history.push("/dashboard");
  }
  dashboard =()=>{ 
    this.setState({ ...this.state, collapsed: true, isShowSider: false })
    }
   
  referrals =()=>{ 
    this.setState({ ...this.state, collapsed: true, isShowSider: false })
   }
    bonuses =()=>{ 
      this.setState({ ...this.state, collapsed: true, isShowSider: false })}
      transfer =()=>{ 
        this.setState({ ...this.state, collapsed: true, isShowSider: false })}
  showToggle = () => {
    this.setState({ ...this.state, collapsed: !this.state.collapsed, isShowSider: true })
  }
  showTransferDrawer=()=>{
    this.setState({...this.state,transferDrawer:true})
  }
  closeDrawer=()=>{
    this.setState({...this.state,transferDrawer:false})
  }
    render() {

        return (
          <>
            <Layout className="layout">
              <div className="tlv-header" id="area">
                <div className="login-user">
                <div>
                  
                </div>
                <ul className="header-logo pl-0">
                <li className="visible-mobile pr-24 p-relative" onClick={this.showToggle}>
                {this.state.collapsed ?
                     <span className="icon lg hamburg " />:<span className="icon md close-dark " /> }
                </li>
                <li className="mobile-logo ">
                  {
                    <img
                      src={logoWhite}
                      alt="logo"
                      className="tlv-logo dark c-pointer"
                      onClick={this.routeToHome}
                    />
                  }
                </li>
                <li className="visible-mobile ml-8 pr-24 p-relative"><Text className="text-white-30 fs-24 ">|</Text>
                      <Text className="text-white-30 fs-24 ml-16 ">
                        Partner
                      </Text></li>
                <li className="mb-d-none  px-36">
                <Link to='/dashboard'  onClick={()=>this.onClickmenu('1')}>
                      <Text
                        content="Dashboard"
                        className="text-white-30 fs-24 c-pointer cp-link mr-12"

                      >
                        Dashboard
                      </Text>
                      </Link>
                      <Text className="text-white-30 fs-24 ">|</Text>
                      <Text className="text-white-30 fs-24 ml-16 ">
                        Partner
                      </Text>
                </li>
               
              </ul>
              <Menu
                theme="light"
                mode="horizontal"
                className="header-right mobile-header-right"
              >
                <Dropdown
                  overlay={this.userProfileMenu}
                  trigger={["click"]}
                  placement="topRight"
                  arrow
                  overlayClassName="secureDropdown"
                >
                  <Menu.Item key="7">
                  
                      <img
                        src={
                           DefaultUser
                        }
                        className="user-profile"
                        alt={"image"}
                      />
                  </Menu.Item>
                </Dropdown>
              </Menu>
                </div>
                <Menu
                  theme="light"
                  mode="horizontal"
                  className="header-right mobile-headerview mb-d-none"
                  selectedKeys={[this.state.selectedkeys]}
                  onClick={(e)=>this.onClickmenu(e.key)}
                >
                   <Menu.Item key="4" className="text-plain list-item c-pointer" onClick={this.showTransferDrawer}>Transfer
                  </Menu.Item>
                 <Menu.Item key="2" className="text-plain list-item">
                  <Link to="/referrals" className='text-white'>Referrals</Link>
                  </Menu.Item>
                  <Menu.Item key="3" className="text-plain list-item">
                  <Link to="/bonuses" className='text-white'>Bonuses</Link>
                  </Menu.Item>
                   <Dropdown
                  overlay={this.userProfileMenu}
                  trigger={["click"]}
                  placement="topRight"
                  arrow
                  overlayClassName="secureDropdown"
                >
                  <Menu.Item key="7">
                  
                      <img
                        src={
                           DefaultUser
                        }
                        className="user-profile"
                        alt={"image"}
                      />
                  </Menu.Item>
                </Dropdown>
                <Transfor showDrawer={this.state.transferDrawer} onClose={() => this.closeDrawer()} />

                </Menu>
                {this.state.isShowSider && <Sider trigger={null}
              collapsible
              collapsed={this.state.collapsed}
              collapsedWidth={0}
              className={` ${this.state.collapsed ? '' : "sideropen"}`}>
             <Menu
                  theme="light"
                  className=""
                  selectedKeys={[this.state.selectedkeys]}
                  onClick={(e)=>this.onClickmenu(e.key)}
                >
                  <Menu.Item key="1" className="text-plain list-item" onClick={this.dashboard}>
                  <Link to="/dashboard" className='text-white'>Dashboard</Link>
                  </Menu.Item>
                 <Menu.Item key="2" className="text-plain list-item" onClick={this.referrals}>
                 <Link to="/referrals" className='text-white'>Referrals</Link>
                  </Menu.Item>
                  <Menu.Item key="3" className="text-plain list-item" onClick={this.bonuses}>
                  <Link to="/bonuses" className='text-white'>Bonuses</Link>
                  </Menu.Item>
              </Menu>

            </Sider>}
              </div>
            </Layout>
          </>
        );
    }
}

const connectStateToProps = ({ userConfig }) => {
    return { userConfig: userConfig.userProfileInfo}
}
export default connect(connectStateToProps)(AppHeader);