import React, { Component } from 'react';
import Typography from 'antd/lib/typography';
import List from 'antd/lib/list';
import Image from 'antd/lib/image';
import Alert from 'antd/lib/alert'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {getpartnerDashboardFiats,isErrorDispaly} from '../../api/apiCalls'


class Wallets extends Component {
  state = {
    wallets: [],
    loading: true,
    errorMsg:null
  };
  componentDidMount() {
    this.fetchWallets();
  }
  fetchWallets= async()=> {
    const fiatRes = await getpartnerDashboardFiats("Partner");
    if (fiatRes.ok) {
      this.setState({ ...this.state, wallets: fiatRes.data,loading:false });
    }else{
      this.setState({...this.state,errorMsg:isErrorDispaly(fiatRes)})
    }
  }

  render() {
    const { Title, Text } = Typography;
      const {wallets,errorMsg} = this.state;
    return (
      <>
      {errorMsg !== null && (
              <Alert
                type="error"
                description={errorMsg}
                closable={false}
                onClose={() => errorMsg(null)}
                showIcon
              />
            )}
        <Title
          className="fs-24 fw-600 mb-12 text-white px-4"
        >Partner Balance</Title>
        <Title
          className="fs-20 fw-600 mb-12 text-white px-4"
        >Fiat Balances</Title>
        <List
          itemLayout="horizontal"
          dataSource={wallets}
          bordered={false}
          className="mobile-list"
          loading={this.state.loading}
          renderItem={(item) => (
            <List.Item className="py-10 px-0">
              <List.Item.Meta
                avatar={<Image preview={false} src={item.imagePath} />}
                title={
                 <div className='d-flex justify-content'>
                  <div className="fs-16 fw-600 text-upper text-white-30 l-height-normal">
                    {item.walletCode}
              
                  </div>
                  <div><Text className='text-white'>{item.amount ||0}</Text></div>
                  </div>
                }
              />
               </List.Item>
          )}
        />
      </>
    );
  }
}
const connectStateToProps = ({ userConfig }) => {
	return { userConfig: userConfig.userProfileInfo };
};

export default connect(connectStateToProps)(withRouter(Wallets));