import GridList from "../grid.component";
import React from "react";
import { onExcellExport } from '../grid.component/subscribir';
import Tooltip from 'antd/lib/tooltip';
const Referals = () =>{
    const columns = [
		
		{field: "joiningDate", title: "Joining Date", filter: true, filterType: "date",width:250,isShowTime: true},
		{ field: "name", title: "Business Name/Personal Name", filter: true,width:300 },
		{ field: "bonusEarned", title: "Bonus Earned", filter: false,dataType: "number", filterType: "numeric",width:250},
		{ field: "tradeIntiated", title: "Trade Initiated", filter: false,width:250},
	]
    const gridRef = React.createRef();
    return(<>
	<div className="main-container">

	
        <div className="box basic-info text-white mt-36 tbl-line refferals-panel">
		<div class="d-flex align-center justify-content mb-16">
			<h1 class="basicinfo mb-0"><span class="ant-typography basicinfo">Referrals</span></h1>
			<Tooltip placement="top" title={"Export Excel"}>
            <span className={`icon c-pointer xl mr-0 excelexport` }onClick={() => {onExcellExport()}}>
			</span>
         </Tooltip>

		</div>
					<GridList
						showActionBar={true}
						ref={gridRef}
						url={process.env.REACT_APP_GRID_API_END_POINT + `Partner/GetRefferalCodesK`}
						columns={columns}
						excelFileName = {'Referrals'}
					/>
				</div>
				</div>
        </>
    )
}
export default Referals;