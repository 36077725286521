import React, {useState } from 'react';
import Typography from 'antd/lib/typography';
import Button from 'antd/lib/button';
import Alert from 'antd/lib/alert';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { saveTransfor } from './api';
import { connect } from 'react-redux';
import Currency from "../Shared/number.formate";
import {setStepcode} from '../../reducers/transferReducer'
import { isErrorDispaly } from '../../api/apiCalls';

const { Text, Paragraph } = Typography;

const  TransforSummary = ({transforObj,dispatch}) =>{
   
   const [errorMsg, setErrorMsg] = useState(null)
   const [onCheked, setChecked] = useState(null)
   const [btnLoader, setBtnLoader] = useState(false)
 
    const saveTransfordata = async (values) => {
        if (onCheked) {
            setBtnLoader(true)
            const res = await saveTransfor(values);
            if (res.ok) {
                dispatch(setStepcode('tranforsuccess'))
                setBtnLoader(false)
            }else{
                setBtnLoader(false)
                setErrorMsg(isErrorDispaly(res))
            }
        } else {
            setBtnLoader(false)
            setErrorMsg("Please click the checkbox after reading and agreeing to the Terms of Service before proceeding")
        }
    }
   
    return (<>
    {errorMsg !== null && (
              <Alert
                className="mb-12"
                description={errorMsg}
                onClose={() => setErrorMsg(null)}
                showIcon
                type="error"
              />
            )}
        <div>
          
            <Row className='my-8'>  
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className='px-8 pb-8'>
                    <Text className="fs-14 text-white-50 fw-200">From Wallet
                    </Text>
                    </Col>
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className='px-8 pb-8'>
                    <div>
                        
                    <Text className="fs-20 text-white-30 mb-36">
                        {transforObj?.fromWalletType}
                    </Text>
                    </div>
                </Col>
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className='px-8 pb-8'>
                    <Text className="fs-14 text-white-50 fw-200">To Wallet
                    </Text>
                    </Col>
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className='px-8 pb-8'>
                    <div>
                    <Text className="fs-20 text-white-30 mb-36">
                    {transforObj?.ToWalletType}
                    </Text></div>
                </Col>
            </Row>

            <Row className='my-8'>
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className='px-8 pb-8'>
                    <Text className="fs-14 text-white-50 fw-200">Currency
                    </Text>
                    </Col>
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className='px-8 pb-8'>
                    <div>
                    <Text className="fs-20 text-white-30 mb-36">
                    {transforObj?.walletCode}
                    </Text>
                    </div>
                </Col>
            <Col xs={12} md={12} lg={12} xl={12} xxl={12} className='px-8 pb-8 pb-8'>
                <Text className="fs-14 text-white-50 fw-200">Amount
                </Text>
                </Col>
                <Col xs={12} md={12} lg={12} xl={12} xxl={12} className='px-8 pb-8'>
                <div>
                <Currency
                    className="fs-20 text-white-30 mb-36"
                    defaultValue={transforObj?.transferAmount}
                    prefix={''}
                />
                </div>
                </Col>
            </Row>
            <Row className='my-8'>
                <div className="d-flex p-16 mb-36 agree-check">
                    <label
                    >
                        <input
                            type="checkbox"
                            id="agree-check"
                        checked={onCheked}
                        onChange={({ currentTarget: { checked } }) => {
                        	setChecked(checked);
                        }}
                        />
                        <span for="agree-check" />
                    </label>
                    <Paragraph
                        className="fs-14 text-white-30 ml-16 mb-0"
                        style={{ flex: 1 }}>
                        <Text className='text-white'>I agree to Suissebase’s</Text>
                        {" "}
                        <a
                            className="textpure-yellow"
                            href="https://www.iubenda.com/terms-and-conditions/42856099"
                            target="_blank">
                            <Text className="textpure-yellow" >Terms of Service</Text>
                        </a>{" "}
                         <Text className='text-white'>and its return, refund and cancellation policy.</Text>
                    </Paragraph>
                </div>
            </Row>
                <Button
                    htmlType="submit"
                    size="large"
                    block
                    className="pop-btn"
                    loading={btnLoader}
                    onClick={() => saveTransfordata(transforObj)}
                >
                    <Text style={{ marginLeft: "15px" }}>Confirm Transfer</Text>
                </Button>
        </div>
    </>)

} 
const connectStateToProps = ({ userConfig,TransforStore }) => {
    return { userProfile: userConfig.userProfileInfo,transforObj:TransforStore.transforObj }
}
const connectDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(TransforSummary);
