import {getMember} from "../api/apiCalls";
const USEER_INFO = "userInfo";
const UPDATE_DOC_REQUEST = "updateDocRequest";
const FETCH_TRACK_AUDITLOGS = "fetchtrackauditlogs";
const CLEAR_USER_INFO = "clearUserInfo";
const userInfo = (payload) => {
    return {
        type: USEER_INFO,
        payload
    }
};
const fetchtrackauditlogs = (payload) => {
    return {
        type: FETCH_TRACK_AUDITLOGS,
        payload
    }
};
const updateDocRequest = (payload) => {
    return {
        type: UPDATE_DOC_REQUEST,
        payload
    }
};
const clearUserInfo = ()=>{
    return {type:CLEAR_USER_INFO,payload:null}
}
const getmemeberInfo = () => {
    return async (dispatch) => {
        getMember().then((res) => {
            if (res.ok) {
                dispatch(userInfo(res.data));
            }
        });
    }
}



let initialState = {
    userProfileInfo: null,
    trackAuditLogData: {}
};
const UserConfig = (state,action) => {
    if(!state){
        state={
            ...initialState,
            ...state
        }
    }
    switch (action.type) {
        case USEER_INFO:
            state = { ...state, userProfileInfo: action.payload }
            return state;
        case UPDATE_DOC_REQUEST:
            state = { ...state, userProfileInfo: { ...state.userProfileInfo, isDocsRequested: action.payload } }
            return state;
        case FETCH_TRACK_AUDITLOGS:
            state = { ...state, trackAuditLogData: action.payload }
            return state;
        case CLEAR_USER_INFO:
            state = { userProfileInfo: null, trackAuditLogData: {} };
            return state;
        default:
            return state;
    }
}

export default UserConfig;
export { userInfo, getmemeberInfo, updateDocRequest, fetchtrackauditlogs,clearUserInfo };