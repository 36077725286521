import { apiMain, apigrid} from "./clients"
import{ApiControllers} from './config';

 const getMember = () => {
  return apiMain.get(ApiControllers.customers + `App/Partner`);
}
const getpartnerDashboardErnings = ( fromdate) => {
  return apiMain.get(ApiControllers.partner + `dashboarddata/customer${fromdate?'/'+fromdate:''}`);
}
const getpartnerDashboardCryptos = (Type) => {
  return apiMain.get(ApiControllers.wallets + `CryptoPortFolio/${Type}`);
}
const getpartnerDashboardFiats = (Type) => {
  return apiMain.get(ApiControllers.wallets + `FiatDashboard/${Type}`);
}
const getpartnerTranDashboard = (Type) => {
  return apigrid.get(ApiControllers.partner + `GetBonusesK/customer/?page=1&pageSize=5`);
}
const convertUTCToLocalTime = (dateString) => {
  let date = new Date(dateString);
  const milliseconds = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
  );
  return new Date(milliseconds).toISOString()
};
const isErrorDispaly = (objValue) => {
  if (objValue.data && typeof objValue.data === "string") {
      return objValue.data;
  } else if (
      objValue.originalError &&
      typeof objValue.originalError.message === "string"
  ) {
      return objValue.originalError.message;
  } else {
      return "Something went wrong please try again!";
  }
};

export {getMember, getpartnerDashboardErnings, getpartnerDashboardCryptos, getpartnerDashboardFiats, getpartnerTranDashboard, convertUTCToLocalTime,isErrorDispaly}
